@import '~Styles/variables.scss';
@import '~Styles/mixins.scss';

// also depend by HeroContent
$app-header-height: 50px;
$app-header-height-m: $app-header-height + 12px;
$app-header-height-l: $app-header-height + 32px;

.appHeader {
  display: flex;
  align-items: center;
  background-color: transparent;
  z-index: 1000;
  width: 100%;
  height: $app-header-height;

  @include media-m {
    align-items: flex-end;
    height: $app-header-height-m;
  }

  @include media-l {
    align-items: flex-end;
    height: $app-header-height-l;
  }

  .content {
    position: relative;
    display: grid;
    align-items: center;
    height: $app-header-height;

    @include media-xs {
      grid-template-columns: 44px 44px 1fr 44px 33px;
    }

    @include media-s {
      grid-template-columns: 44px 44px 1fr 44px 44px;
    }

    @include media-m {
      grid-column-gap: 8px;
      grid-template-columns: 94px 1fr 200px auto;
      height: $app-header-height;
    }

    @include media-l {
      grid-column-gap: 40px;
      grid-template-columns: 130px 1fr 300px auto;
    }

    @include media-xl {
      grid-column-gap: 52px;
    }
  }

  .contentOld {
    position: relative;
    display: grid;
    grid-template-columns: 44px 44px 1fr 44px 44px;
    align-items: center;
    height: $app-header-height;

    @include media-m {
      grid-column-gap: 8px;
      grid-template-columns: 94px 1fr 200px auto;
      height: $app-header-height;
    }

    @include media-l {
      grid-column-gap: 40px;
      grid-template-columns: 130px 1fr 300px auto;
    }

    @include media-xl {
      grid-column-gap: 52px;
    }
  }
}

.appLogo {
  grid-row: 1;
  grid-column: 1 / span 5;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1010;
  font-size: 0;
  line-height: 1;

  pointer-events: none;

  a {
    pointer-events: all;
  }

  @include media-m {
    grid-column: 1 / span 1;
  }

  .appLogoIcon {
    color: $color-base-primary-light;
    height: 30px;

    @include media-m {
      width: 94px;
      height: 32px;
    }

    @include media-l {
      width: 130px;
      height: 44px;
    }
  }
}

.nav {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-row: 1;
  grid-column: 2 / span 2;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  background-color: $color-base-primary-dark;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100% auto;
  background-image: url('~@skytvnz/sky-app-style/lib/assets/svg/backgrounds/app_bg_xs.svg');

  @include media-m {
    display: block;
    position: static;
    background: none;
  }
}

.navMobileShow {
  display: flex;

  @include media-m {
    display: block;
  }
}

.navLink {
  position: relative;
  padding: 0 $size-layout-spacing-xs;
  margin: $size-layout-spacing-s 0;
  z-index: 1;

  @include heading-4;
  font-size: 24px;
  line-height: 28px;

  @include media-m {
    display: inline-block;
    margin: 0 4px;
    font-size: 14px;
    &:first-child {
      margin-left: 0;
    }
  }
  @include media-l {
    font-size: 14px;
    margin: 0 $size-layout-spacing-m;
  }
  @include media-xl {
    font-size: 18px;
    margin: 0 $size-layout-spacing-m;
  }
  &:hover,
  &.active {
    color: $color-base-primary-light;
    &::after {
      content: '';
      display: block;
      height: 0;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      @include media-m {
        bottom: -2px;
      }
      border-bottom: 7px $color-base-link solid;
      z-index: -1;
    }
  }
  .profileDropDown {
    width: 175px;

    @include media-xl {
      width: 190px;
    }
  }
}

.profileLink {
  padding-left: 0px;
  &:hover {
    border-radius: 2px solid white;
    &::after {
      content: '';
      border-bottom: none;
      border-radius: 2px solid white;
    }
  }
}

.mobileProfileLink {
  grid-row: 1;
  grid-column: 5;
  width: 215px;

  @include media-m {
    display: none;
  }

  @include media-l {
    display: none;
  }

  @include media-xl {
    display: none;
  }
}

.optout {
  grid-row: 1;
  grid-column: 4 / span 1;
  display: none;
  justify-self: center;
  margin: 0;
  padding: 0 $size-layout-spacing-xs;

  @include media-m {
    display: block;
  }
}

.headerBtn {
  grid-row: 1;

  @include size-1-1(44px);
  font-size: 28px;
  line-height: 1;
  padding: 0;

  @include media-m {
    left: 20px;
    font-size: 24px;
  }

  @include media-l {
    font-size: 32px;
  }

  &:hover {
    color: $color-base-primary-light;
  }
}

.mobileOnlyBtn {
  display: block;

  @include media-m {
    display: none;
  }
}

.mobileBackBtn {
  grid-column: 1 / span 1;
}

.searchPanel {
  grid-row: 1;
  grid-column: 4;

  @include media-m {
    margin-left: 50px;
    grid-column: 3 / span 1;
  }
}

.searchPanelOld {
  grid-row: 1;
  grid-column: 4;

  @include media-m {
    grid-column: 3 / span 1;
  }
}

.authBtn {
  grid-row: 1;
  display: none;
  justify-self: flex-end;
  margin: 0;
  padding: 0;

  @include media-m {
    display: block;
  }
}

.mobileNavBtn {
  grid-column: 1;
  z-index: 1010;
}

.mobileNavBtnOld {
  grid-column: 5;
  z-index: 1010;
}

@mixin hero-banner-spacing {
  margin-bottom: $size-layout-spacing-l;

  @include media-m {
    margin-top: $app-header-height-m * -1; // make the header overlay on top of hero
    margin-bottom: $size-layout-spacing-xxl;
  }

  @include media-l {
    margin-top: $app-header-height-l * -1; // make the header overlay on top of hero
  }
}
