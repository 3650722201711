@import '../variables.scss';

@mixin heading-1 {
  font-weight: $font-base-heading-1-font-weight-m;
  font-size: $font-base-heading-1-font-size-xs;
  line-height: $font-base-heading-1-line-height-xs;

  @include media-s {
    font-weight: $font-base-heading-1-font-weight-xs;
    font-size: $font-base-heading-1-font-size-s;
    line-height: $font-base-heading-1-line-height-s;
  }

  @include media-m {
    font-weight: $font-base-heading-1-font-weight-m;
    font-size: $font-base-heading-1-font-size-m;
    line-height: $font-base-heading-1-line-height-m;
  }

  @include media-l {
    font-weight: $font-base-heading-1-font-weight-l;
    font-size: $font-base-heading-1-font-size-l;
    line-height: $font-base-heading-1-line-height-l;
  }

  @include media-xl {
    font-weight: $font-base-heading-1-font-weight-xl;
    font-size: $font-base-heading-1-font-size-xl;
    line-height: $font-base-heading-1-line-height-xl;
  }
}

@mixin heading-2 {
  font-weight: $font-base-heading-2-font-weight-xs;
  font-size: $font-base-heading-2-font-size-xs;
  line-height: $font-base-heading-2-line-height-xs;

  @include media-l {
    font-weight: $font-base-heading-2-font-weight-xs;
    font-size: $font-base-heading-2-font-size-l;
    line-height: $font-base-heading-2-line-height-l;
  }
}

@mixin heading-3 {
  font-weight: $font-base-heading-3-font-weight-xs;
  font-size: $font-base-heading-3-font-size-xs;
  line-height: $font-base-heading-3-line-height-xs;

  @include media-s {
    font-weight: $font-base-heading-3-font-weight-xs;
    font-size: $font-base-heading-3-font-size-s;
    line-height: $font-base-heading-3-line-height-s;
  }

  @include media-xl {
    font-weight: $font-base-heading-3-font-weight-xs;
    font-size: $font-base-heading-3-font-size-xl;
    line-height: $font-base-heading-3-line-height-xl;
  }
}

@mixin heading-4 {
  font-weight: $font-base-heading-4-font-weight-xs;
  font-size: $font-base-heading-4-font-size-xs;
  line-height: $font-base-heading-4-line-height-xs;
  text-transform: $font-base-heading-4-text-transform-xs;
  letter-spacing: $font-base-heading-4-letter-spacing-xs;

  @include media-m {
    font-weight: $font-base-heading-4-font-weight-m;
    font-size: $font-base-heading-4-font-size-m;
    line-height: $font-base-heading-4-line-height-m;
    text-transform: $font-base-heading-4-text-transform-m;
    letter-spacing: $font-base-heading-4-letter-spacing-m;
  }

  @include media-xl {
    font-weight: $font-base-heading-4-font-weight-xl;
    font-size: $font-base-heading-4-font-size-xl;
    line-height: $font-base-heading-4-line-height-xl;
    text-transform: $font-base-heading-4-text-transform-xl;
    letter-spacing: $font-base-heading-4-letter-spacing-xl;
  }
}

@mixin heading-5 {
  font-weight: $font-base-heading-5-font-weight-xs;
  font-size: $font-base-heading-5-font-size-xs;
  line-height: $font-base-heading-5-line-height-xs;

  @include media-s {
    font-weight: $font-base-heading-5-font-weight-s;
    font-size: $font-base-heading-5-font-size-s;
    line-height: $font-base-heading-5-line-height-s;
  }
  @include media-m {
    font-weight: $font-base-heading-5-font-weight-m;
    font-size: $font-base-heading-5-font-size-m;
    line-height: $font-base-heading-5-line-height-m;
  }
  @include media-l {
    font-weight: $font-base-heading-5-font-weight-l;
    font-size: $font-base-heading-5-font-size-l;
    line-height: $font-base-heading-5-line-height-l;
  }
  @include media-xl {
    font-weight: $font-base-heading-5-font-weight-xl;
    font-size: $font-base-heading-5-font-size-xl;
    line-height: $font-base-heading-5-line-height-xl;
  }
}

@mixin body-1($isBold: false) {
  @if $isBold == true {
    font-weight: 700;
  } @else {
    font-weight: $font-base-body-1-font-weight-xs;
  }
  font-size: $font-base-body-1-font-size-xs;
  line-height: $font-base-body-1-line-height-xs;

  @include media-l {
    @if $isBold == true {
      font-weight: 700;
    } @else {
      font-weight: $font-base-body-1-font-weight-l;
    }
    font-size: $font-base-body-1-font-size-l;
    line-height: $font-base-body-1-line-height-l;
  }

  @include media-xl {
    @if $isBold == true {
      font-weight: 700;
    } @else {
      font-weight: $font-base-body-1-font-weight-xl;
    }
    font-size: $font-base-body-1-font-size-xl;
    line-height: $font-base-body-1-line-height-xl;
  }
}

@mixin body-2($isBold: false) {
  @if $isBold == true {
    font-weight: 700;
  } @else {
    font-weight: $font-base-body-2-font-weight-xs;
  }
  font-size: $font-base-body-2-font-size-xs;
  line-height: $font-base-body-2-line-height-xs;

  @include media-l {
    @if $isBold == true {
      font-weight: 700;
    } @else {
      font-weight: $font-base-body-2-font-weight-m;
    }
    font-size: $font-base-body-2-font-size-l;
    line-height: $font-base-body-2-line-height-l;
  }

  @include media-xl {
    @if $isBold == true {
      font-weight: 700;
    } @else {
      font-weight: $font-base-body-2-font-weight-m;
    }
    font-size: $font-base-body-2-font-size-xl;
    line-height: $font-base-body-2-line-height-xl;
  }
}

@mixin body-3($isBold: false) {
  @if $isBold == true {
    font-weight: 700;
  } @else {
    font-weight: $font-base-body-3-font-weight-xs;
  }
  font-size: $font-base-body-3-font-size-xs;
  line-height: $font-base-body-3-line-height-xs;

  @include media-s {
    @if $isBold == true {
      font-weight: 700;
    } @else {
      font-weight: $font-base-body-3-font-weight-xs;
    }
    font-size: $font-base-body-3-font-size-s;
    line-height: $font-base-body-3-line-height-s;
  }

  @include media-l {
    @if $isBold == true {
      font-weight: 700;
    } @else {
      font-weight: $font-base-body-3-font-weight-m;
    }
    font-size: $font-base-body-3-font-size-l;
    line-height: $font-base-body-3-line-height-l;
  }
}
