@import '~Styles/mixins.scss';

.controlButton {
  pointer-events: all;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
  background-color: transparent;

  height: 24px;

  .buttonIcon {
    font-size: 24px;
    line-height: 1;
    & ~ span {
      margin-left: $size-layout-spacing-xxs;
    }
  }

  @include media-m {
    height: 34px;

    .buttonIcon {
      font-size: 34px;
      & ~ span {
        margin-left: $size-layout-spacing-xs;
      }
    }
  }

  @include media-xl {
    height: 38px;

    .buttonIcon {
      font-size: 38px;
      & ~ span {
        margin-left: $size-layout-spacing-xs;
      }
    }
  }

  @include body-2();
}

@mixin show {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease-out 0s, visibility 0s linear 0s;
}

@mixin hide {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-out 0s, visibility 0s linear 0.3s;
}
@mixin boldButtonText {
  .buttonIcon {
    & ~ span {
      position: relative;
      &::before {
        content: attr(data-text);
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        font-weight: 700;
        white-space: nowrap;
        visibility: hidden;
      }
    }
  }
  &:hover {
    .buttonIcon ~ span::before {
      visibility: visible;
    }
  }
}
