@import 'mixins/font.scss';
@import 'mixins/layout.scss';

@mixin no-user-select {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
}

// Global Mixins
@mixin text-overflow-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin multiline-overflow-elipsis($numberOfLines: 3) {
  display: -webkit-box;
  -webkit-line-clamp: $numberOfLines;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin whiteBackgroundImage() {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background-color: #fff;

  & > img {
    max-width: 100%;
    max-height: 100%;
  }
}

@mixin tilesGap {
  margin-left: rem($size-layout-spacing-xs);

  &:first-child {
    margin-left: 0;
  }

  &:hover {
    z-index: 2;
  }
}

@mixin header-3() {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;

  @include media-l {
    font-size: 30px;
    line-height: 34px;
  }
}

@mixin bold($isBold: false) {
  @if $isBold == true {
    font-weight: 700;
  } @else {
    font-weight: normal;
  }
}

// TODO: replace this one by the default mixin "body", after refactor the responsive breakpoint
@mixin title-2($isBold: false) {
  @include bold($isBold);

  font-size: 14px;
  line-height: 18px;

  @include media-m {
    font-size: 16px;
    line-height: 20px;
  }
}

@mixin title-3($isBold: false) {
  @include bold($isBold);

  font-size: 12px;
  line-height: 16px;

  @include media-m {
    font-size: 14px;
    line-height: 18px;
  }
}

@mixin time-slot {
  display: flex;
  align-items: center;
  padding-left: $size-layout-spacing-s;
  background-color: $color-palette-midnight;
  border-radius: 2px;

  height: $font-base-heading-2-font-size-xs;
  @include media-m {
    height: $font-base-heading-2-font-size-m;
  }
}

// REM
@mixin delimited-rem {
  display: flex;
  align-items: baseline;

  &::after {
    @include delimiter-rem();
  }

  &:last-child {
    &::after {
      content: none;
    }
  }

  &:first-child {
    &::after {
      content: '';
    }
  }
}

@mixin delimiter-rem($diameterM: 6px, $marginM: $size-layout-spacing-m) {
  content: '';
  @include size-1-1-rem(4px);

  display: inline-block;
  vertical-align: middle;
  background-color: $color-base-primary-light;
  border-radius: 50%;
  margin-left: rem($size-layout-spacing-xs);
  margin-right: rem($size-layout-spacing-xs);
  font-size: 0;
  top: 50%;
  transform: translateY(-50%);

  @include media-m {
    @include size-1-1-rem($diameterM);
    margin-left: rem($marginM);
    margin-right: rem($marginM);
  }
}

// NO REM
@mixin delimited($color) {
  display: flex;
  align-items: baseline;

  &::after {
    @include delimiter(6px, $size-layout-spacing-m, $color);
  }

  &:first-child {
    &::after {
      content: '';
    }
  }

  &:last-child {
    &::after {
      content: none;
    }
  }
}

@mixin delimiter(
  $diameterL: 6px,
  $marginL: $size-layout-spacing-m,
  $color: $color-base-primary-light
) {
  content: '';
  @include size-1-1(4px);

  display: inline-block;
  vertical-align: middle;
  background-color: $color;
  border-radius: 50%;
  margin-left: $size-layout-spacing-xs;
  margin-right: $size-layout-spacing-xs;
  font-size: 0;
  top: 50%;
  transform: translateY(-50%);

  @include media-l {
    @include size-1-1($diameterL);
    margin-left: $marginL;
    margin-right: $marginL;
  }
}

@mixin primaryButton() {
  display: flex;
  align-items: center;
  height: 40px;
  padding-left: $size-layout-spacing-xl;
  padding-right: $size-layout-spacing-xl;
  border-radius: 100px;
  @include heading-4;

  @include media-m {
    height: $size-layout-spacing-xxl;
  }

  @include media-xl {
    height: 56px;
  }

  &:hover {
    background-color: #078421;
    color: $color-font-base;
    border-color: #078421;
  }
}

@mixin buttonIcon {
  font-size: 24px;

  @include media-m {
    vertical-align: middle;
    font-size: 32px;
  }

  @include media-xl {
    font-size: 36px;
  }
}

@mixin videoLayoutPanel {
  @include size-16-9-calc(100vw);

  @media (min-width: 100vh * 16 / 9) {
    width: calc(100vh * 16 / 9);
    height: 100vh;
  }
}

@mixin rail($multiLine: false) {
  width: 100%;
  height: calc(var(--responsive-tile-width) / 16 / 9);

  @if $multiLine {
    flex-flow: row wrap;
  } @else {
    flex-flow: row nowrap;

    &::after {
      content: '';
      flex: 0 0 auto;
      width: calc(var(--responsive-grid-edge-width) - (var(--responsive-gutter) / 2));
      margin: calc(var(--responsive-gutter) / 2);
      pointer-events: none;
    }
  }
}

@mixin tileHover($scale) {
  @include media-m {
    &:hover {
      transform: scale($scale);
      z-index: 99;
    }
  }
}

@mixin tile() {
  flex: 0 0 auto;

  width: var(--responsive-tile-width);
  margin-right: var(--responsive-gutter);

  --responsive-tile-width: calc(
    (100vw - var(--responsive-grid-edge-width) * 2) / var(--responsive-tile-number) -
      var(--responsive-gutter)
  );
}

@mixin tile-image {
  @include size-16-9-calc(var(--responsive-tile-width));
}

@mixin responsive-tile-s() {
  @include tile();
  --responsive-tile-number: 2;

  @include media-s {
    --responsive-tile-number: 3;
  }

  @include media-m {
    --responsive-tile-number: 4;
  }

  @include media-l {
    --responsive-tile-number: 5;
  }

  @include media-xl {
    --responsive-tile-number: 6;
  }
}

@mixin responsive-tile-l() {
  @include tile();
  --responsive-tile-number: 1;

  @include media-s {
    --responsive-tile-number: 2;
  }

  @include media-m {
    --responsive-tile-number: 2;
  }

  @include media-l {
    --responsive-tile-number: 3;
  }

  @include media-xl {
    --responsive-tile-number: 3;
  }
}
