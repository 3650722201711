@import '../variables.scss';

// Responsive px to rem
@function rem($px) {
  @return $px / $size-layout-responsive-root-font-size * 1rem;
}

@function aspect-ratio-size($width, $ratio: 1/1) {
  @return $width / $ratio;
}

@mixin aspect-ratio-size($width, $ratio) {
  width: rem($width);
  height: rem(aspect-ratio-size($width, $ratio));
}

// Aspect Ratio Sizes
// Calc
@mixin size-1-1-calc($width) {
  @include aspect-ratio-size-calc($width, 1/1);
}

@mixin size-2-3-calc($width) {
  @include aspect-ratio-size-calc($width, 2/3);
}

@mixin size-16-9-calc($width) {
  @include aspect-ratio-size-calc($width, 16/9);
}

@mixin aspect-ratio-size-calc($width, $ratio) {
  width: $width;
  height: calc(#{aspect-ratio-size($width, $ratio)});
}

// NO REM
@mixin size-1-1($width) {
  @include aspect-ratio-size($width, 1/1);
}

@mixin size-2-3($width) {
  @include aspect-ratio-size($width, 2/3);
}

@mixin size-4-3($width) {
  @include aspect-ratio-size($width, 4/3);
}

@mixin size-16-9($width) {
  @include aspect-ratio-size($width, 16/9);
}

@mixin aspect-ratio-size($width, $ratio) {
  width: $width;
  height: aspect-ratio-size($width, $ratio);
}

// Aspect Ratio Sizes
// REM
@mixin size-1-1-rem($width) {
  @include aspect-ratio-size-rem($width, 1/1);
}

@mixin size-2-3-rem($width) {
  @include aspect-ratio-size-rem($width, 2/3);
}

@mixin size-16-9-rem($width) {
  @include aspect-ratio-size-rem($width, 16/9);
}

@mixin aspect-ratio-size-rem($width, $ratio) {
  width: rem($width);
  height: rem(aspect-ratio-size($width, $ratio));
}

// Breakpoints media query
@mixin screen-media($breakpoint) {
  @media (min-width: $breakpoint) {
    @content;
  }
}

@mixin media-xs {
  @include screen-media($size-layout-breakpoint-xs) {
    @content;
  }
}

@mixin media-s {
  @include screen-media($size-layout-breakpoint-s) {
    @content;
  }
}

@mixin media-m {
  @include screen-media($size-layout-breakpoint-m) {
    @content;
  }
}

@mixin media-l {
  @include screen-media($size-layout-breakpoint-l) {
    @content;
  }
}

@mixin media-xl {
  @include screen-media($size-layout-breakpoint-xl) {
    @content;
  }
}

@mixin content() {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  padding-left: var(--responsive-grid-edge-width);
  padding-right: var(--responsive-grid-edge-width);
}

// Grid System - CSS Flexbox
@mixin row($multiLine: false) {
  display: flex;

  width: calc(100% + var(--responsive-gutter));
  margin: calc(var(--responsive-gutter) / 2 * -1);

  @if $multiLine {
    flex-flow: row wrap;
  } @else {
    flex-flow: row nowrap;

    @include hide-scroll-bar();
    overflow-x: scroll;

    &::after {
      content: '';
      flex: 0 0 auto;
      width: calc(var(--responsive-grid-edge-width) - (var(--responsive-gutter) / 2));
      margin: calc(var(--responsive-gutter) / 2);
      pointer-events: none;
    }
  }
}

@mixin col($span: 1) {
  flex: 0 0 auto;

  width: calc((100% / var(--responsive-grid-col-number)) * #{$span} - var(--responsive-gutter));
  margin: calc(var(--responsive-gutter) / 2);
}

// Grid System - CSS Grid
@mixin grid($row: 0, $rowGap: false) {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(var(--responsive-grid-col-number), 1fr);

  grid-column-gap: var(--responsive-gutter);
  column-gap: var(--responsive-gutter);

  @include grid-row($row);
  @include grid-row-gap($rowGap);
}

@mixin grid-row($row: 0, $rowHeight: 1fr) {
  @if type-of($row) == 'number' and $row > 0 {
    grid-template-rows: repeat($row, $rowHeight);
  }
}

@mixin grid-row-gap($rowGap: false) {
  @if $rowGap {
    @if type-of($rowGap) == 'number' {
      grid-row-gap: $rowGap;
      row-gap: $rowGap;
    } @else {
      grid-row-gap: var(--responsive-gutter);
      row-gap: var(--responsive-gutter);
    }
  }
}

@mixin cell($rowStart: auto, $colStart: auto, $rowSpan: auto, $colSpan: auto) {
  @include cell-col($colStart, $colSpan);
  @include cell-row($rowStart, $rowSpan);
}

@mixin cell-col($start: auto, $span: auto) {
  @include cell-col-start($start);
  @include cell-col-span($span);
}

@mixin cell-col-start($start) {
  @if type-of($start) == 'number' and $start > 0 {
    grid-column-start: $start;
  }
}

@mixin cell-col-span($span) {
  @if type-of($span) == 'number' and $span > 0 {
    grid-column-end: span $span;
  }
}

@mixin cell-row($start: auto, $span: auto) {
  @include cell-row-start($start);
  @include cell-row-span($span);
}

@mixin cell-row-start($start) {
  @if type-of($start) == 'number' and $start > 0 {
    grid-row-start: $start;
  }
}

@mixin cell-row-span($span) {
  @if type-of($span) == 'number' and $span > 0 {
    grid-row-end: span $span;
  }
}

// Scroll Bar Util
@mixin hide-scroll-bar {
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
    color: transparent;
    background: transparent;
  }
}

@mixin displayOnlyM($display: inherit) {
  display: none !important;

  @include media-m {
    display: $display !important;
  }
}

@mixin displayOnlyXs($display: inherit) {
  display: $display !important;

  @include media-m {
    display: none !important;
  }
}
