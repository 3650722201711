// Import the global style variables as an internal bridge for Web App to consume the variables from .scss
@import '~@skytvnz/sky-app-style/lib/skyGo/variables.scss';

// Define the Web App specific variables
$width-tv-guide-column-s: 93px;
$width-tv-guide-column-m: 124px;
$width-tv-guide-column-l: 153px;
$width-tv-guide-column-xl: 232px;

$height-tv-guide-column-xs: 20px;
$height-tv-guide-column-s: 52px;
$height-tv-guide-column-m: 70px;

$tile-border-radius: $size-layout-border-radius-default * 6;
