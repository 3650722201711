video::cue {
  color: white;
  font-feature-settings: 'liga' off, 'clig' off;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
}
video::-webkit-media-text-track-display{
  text-align:center !important;
}
@media (min-width:390px) and (max-width:767px) {
  video::cue{
    font-size: 16px;
  }  
}
@media (min-width:768px) {
  video::cue{
    font-size: 24px;
    font-weight: 700;
  }  
}
@media (min-width:1024px){
  video::-webkit-media-text-track-display{
    left:40% !important;
    width:fit-content !important;
    transform: translateY(-50%);
  }
}