@import '~Styles/variables.scss';
@import '~Styles/mixins.scss';

// Global CSS Variables. Only import into the App once.
@import '~Styles/css-variables.scss';

@mixin html-font-size {
  $root-rem: ($size-layout-responsive-root-font-size / 1px);
  $design-xs: ($size-layout-responsive-design-width-xs / 1px);
  $design-m: ($size-layout-responsive-design-width-m / 1px);

  font-size: $size-layout-breakpoint-xs / $design-xs * $root-rem;

  @include media-xs {
    font-size: calc(100vw / #{$design-xs} * #{$root-rem});
  }

  @include media-m {
    font-size: calc(100vw / #{$design-m} * #{$root-rem});
  }
}

$font-name: 'MarkPro';

@font-face {
  font-family: $font-name;
  font-weight: 300;
  font-display: swap;
  src: url('./Resources/fonts/MarkProLight.woff2') format('woff2'),
    url('./Resources/fonts/MarkProLight.woff') format('woff'),
    url('./Resources/fonts/MarkProLight.ttf') format('truetype');
}

@font-face {
  font-family: $font-name;
  font-weight: 400;
  font-display: swap;
  src: url('./Resources/fonts/MarkProBook.woff2') format('woff2'),
    url('./Resources/fonts/MarkProBook.woff') format('woff'),
    url('./Resources/fonts/MarkProBook.ttf') format('truetype');
}

@font-face {
  font-family: $font-name;
  font-weight: 700;
  font-display: swap;
  src: url('./Resources/fonts/MarkProBold.woff2') format('woff2'),
    url('./Resources/fonts/MarkProBold.woff') format('woff'),
    url('./Resources/fonts/MarkProBold.ttf') format('truetype');
}

@font-face {
  font-family: $font-name;
  font-weight: 900;
  font-display: swap;
  src: url('./Resources/fonts/MarkProBlack.woff2') format('woff2'),
    url('./Resources/fonts/MarkProBlack.woff') format('woff'),
    url('./Resources/fonts/MarkProBlack.ttf') format('truetype');
}

@font-face {
  font-family: 'BASE';
  src: local('Source Sans Pro'), local('-apple-system'), local('BlinkMacSystemFont'),
    local('Segoe UI'), local('Roboto'), local('Oxygen'), local('Ubuntu'), local('Cantarell'),
    local('Fira Sans'), local('Droid Sans'), local('Helvetica Neue'), local(Arial),
    local(sans-serif);
}

:global {
  //noinspection CssInvalidAtRule
  @import-normalize;

  *:focus {
    outline: none;
  }

  html {
    @include html-font-size();
  }

  body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: auto;
    overflow-x: hidden;
    color: $color-base-primary-light;
    background-color: $color-base-primary-dark;
    font-family: $font-name, BASE, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @include body-2;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }

  h1 {
    @include heading-1;
  }

  h2 {
    @include heading-2;
  }

  h3 {
    @include heading-3;
  }

  h4 {
    @include heading-4;
  }

  h5 {
    @include heading-5;
  }

  #app {
    position: relative;
    display: flex;
    overflow-x: hidden;
    flex-direction: column;
    width: 100vw;
    min-width: $size-layout-breakpoint-xs;
    min-height: 100vh;
  }

  .displayOnlyM {
    @include displayOnlyM();
  }

  .displayOnlyXs {
    @include displayOnlyXs();
  }

  .anticon svg {
    display: block;
  }

  ::selection {
    background: #5d789d;
  }
}

// Used for triggering an update to html tag when the window resized.
.htmlRem {
  @include html-font-size();
}
