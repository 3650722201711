@import 'mixins/layout.scss';

@import '~@skytvnz/sky-app-style/lib/skyGo/variables.css';

:root {
  //
  --responsive-gutter: var(--size-layout-gutter-xs);
  @include media-s {
    --responsive-gutter: var(--size-layout-gutter-s);
  }
  @include media-m {
    --responsive-gutter: var(--size-layout-gutter-m);
  }
  @include media-l {
    --responsive-gutter: var(--size-layout-gutter-l);
  }

  //
  --responsive-grid-col-number: var(--size-layout-grid-col-number-s);

  //
  --responsive-grid-edge-width: var(--size-layout-grid-edge-width-xs);

  @include media-s {
    --responsive-grid-edge-width: var(--size-layout-grid-edge-width-s);
  }

  @include media-m {
    --responsive-grid-edge-width: var(--size-layout-grid-edge-width-m);
  }

  @include media-l {
    --responsive-grid-edge-width: var(--size-layout-grid-edge-width-l);
  }

  @include media-xl {
    --responsive-grid-edge-width: var(--size-layout-grid-edge-width-xl-1680);
  }

  //
  --responsive-section-gutter: var(--size-layout-section-xs);
  @include media-m {
    --responsive-section-gutter: var(--size-layout-section-m);
  }
  @include media-xl {
    --responsive-section-gutter: var(--size-layout-section-xl);
  }
}
